// NavList.Group is an ActionList.Divider followed by an ActionList.Group.
// The divider is hidden if the group is the first child of the list.
// By default, ActionList.Group has a rule to suppress the margin if it's the first child.
// But the existence of the divider means that the *hidden* divider is the first child,
// giving the group a margin-top anyway.
// This suppresses that margin for the first group, which is actually the *second* child.
.nav-list {
  li[data-component='ActionList.Group']:nth-child(2) {
    margin-top: 0;
  }

  // Same thing for the first top-level item.
  & > ul > li:nth-child(2) {
    margin-top: 0;
  }
}
